import './App.css';

// Router
import { Navigate, Routes, Route } from 'react-router-dom';

// Components
import TopNav from '../../components/global/TopNav';

// Pages
import Home from '../Home/HomePage';

function App() {
  return (
    <div className="App">
      <div className="innerApp">
        <div className='page'>
          <TopNav />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;
