import './HomePage.css'
import { useState, useEffect } from 'react';
import { Form, Button, ButtonGroup } from 'react-bootstrap';

export default function HomePage(){

    const [showButton, setShowButton] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    };

    const handleClick = () => {
        window[`scrollTo`]({ top: 0, behavior: `smooth` })
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Send email with form details
        const response = await fetch('/send-email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ name, email, message }),
        });
    
        if (response.ok) {
          alert('Email sent successfully!');
        } else {
          alert('Error sending email.');
        }
    
        // Clear form fields
        setName('');
        setEmail('');
        setMessage('');
      };

    return (
        <div className='HomePage'>
            {/* <div className='name-text'>
                <h1>Manny Dinssa</h1>
            </div> */}
            <section className='intro bg-purple pb-0'>
                <div className='inner row'>
                    <div className='intro-text col-md-5 d-flex flex-column justify-content-center align-content-center text-white'>
                        <h2 className='fs-3'>👋 Hi! I'm Manny, a full-stack developer based in London, with a passion for UX UI Design.</h2>
                    </div>
                    <div className='intro-img col-md-7'>
                        {/* <img className='me-img' src={process.env.PUBLIC_URL + '/assets/splash3.png'} alt='Image' /> */}
                    </div>
                </div>
            </section>
            <section id='about' className='about'>
                <div className='inner pt-5 mt-5 mt-lg-3'>
                    <div className='about-text container'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <h1 className='about-start fw-bold pb-3 pe-md-4 fs-1'>I build web apps that make a positive impact and solve overlooked problems</h1>                                
                            </div>
                            <div className='col-md-6'>
                                <p>
                                I am a software engineer with a passion for creative work and design. I have skills across Full-Stack development in numerous programming languages, frameworks and a special interest in UX design - while eager to learn more.
                                </p>
                                <p>
                                With a background in mathematics and analytics I can solve complex problems with innovative solutions.
                                </p>
                                <p>
                                I'm looking for work that can make an impact and is meaningful to the soul; where authenticity is appreciated and expected; where I can fuel my curiosity and rise to challenges.                                </p>
                                <p>
                                I deliver software solutions that are user-friendly, scalable and secure, and make a positive impact on people’s lives.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='portfolio' className='portfolio p-0'>
                <div className='inner d-flex flex-column justify-content-center align-content-center'>
                    <div className='portfolio-text'>
                    </div>
                    <div className='portfolio-item mt-5 bg-light container mb-3 mb-md-4 mb-lg-5'>
                        <div className='row h-100'>
                            <div className='portfolio-item-bio col-md-7 hmhu p-md-5'>
                                <div className='tags-list'>
                                </div>
                                <h1 className='portfolio-item-title mb-4'>Help Me Help You</h1>
                                <p className='portfolio-item-desc mb-4 fs-5'>
                                    Freelancer tool to simplify commmunication between client and developer.
                                </p>
                                <ButtonGroup className='mb-5 mb-sm-0'>
                                <a href='https://github.com/Dinssa/hmhu-ga' target='_blank'>
                                    <Button variant='dark' className='fw-bold p-2 px-3 p-md-3 px-md-5'>
                                    ReadMe
                                    </Button>
                                </a>
                                <a href='https://ga.hmhu.io/' target='_blank' className='ps-2'>
                                    <Button variant='dark' className='fw-bold p-2 px-3 p-md-3 px-md-5'>
                                    Demo
                                    </Button>
                                </a>
                                </ButtonGroup>
                            </div>
                            <div id='hmhu-img' className='col-md-5 portfolio-item-img'>

                            </div>
                        </div>
                    </div>
                    <div className='portfolio-item mt-5 bg-light container mb-3 mb-md-4 mb-lg-5'>
                        <div className='row h-100'>
                            <div id='rightspot-img' className='col-md-5 portfolio-item-img'>

                            </div>
                            <div className='portfolio-item-bio col-md-7 rightspot p-md-5'>
                                <div className='tags-list'>
                                </div>
                                <h1 className='portfolio-item-title mb-4'>Right Spot</h1>
                                <p className='portfolio-item-desc mb-4 fs-5'>
                                    Find the perfect location for your next business venture. In your own project, find a spot and compare it to other spots using extensive economic data.
                                </p>
                                <ButtonGroup className='mb-5 mb-sm-0'>
                                <a href='https://github.com/Dinssa/RightSpot' target='_blank'>
                                    <Button variant='dark' className='fw-bold p-2 px-3 p-md-3 px-md-5'>
                                    ReadMe
                                    </Button>
                                </a>
                                <a href='https://rightspot.fly.dev/' target='_blank' className='ps-2'>
                                    <Button variant='dark' className='fw-bold p-2 px-3 p-md-3 px-md-5'>
                                    Demo
                                    </Button>
                                </a>
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>
                    <div className='portfolio-item mt-5 bg-light container mb-3 mb-md-4 mb-lg-5'>
                        <div className='row h-100'>
                            <div className='portfolio-item-bio col-md-7 whereToBuy p-md-5'>
                                <div className='tags-list'>
                                </div>
                                <h1 className='portfolio-item-title mb-4'>Where To Buy</h1>
                                <p className='portfolio-item-desc mb-4 fs-5'>
                                    Niche listings website for a singular product or product category. Find out where to buy locally to you.
                                </p>
                                <ButtonGroup className='mb-5 mb-sm-2'>
                                <a href='https://github.com/Dinssa/where-to-buy' target='_blank'>
                                    <Button variant='dark' className='fw-bold p-2 px-3 p-md-3 px-md-5'>
                                    ReadMe
                                    </Button>
                                </a>
                                <a href='https://where-to-buy.fly.dev/' target='_blank' className='ps-2'>
                                    <Button variant='dark' className='fw-bold p-2 px-3 p-md-3 px-md-5'>
                                    Demo
                                    </Button>
                                </a>
                                </ButtonGroup>
                            </div>
                            <div id='whereToBuy-img' className='col-md-5 portfolio-item-img'>

                            </div>
                        </div>
                    </div>
                    <div className='portfolio-item mt-5 bg-light container mb-3 mb-md-4 mb-lg-5'>
                        <div className='row h-100'>
                            <div id='battleship-img' className='col-md-5 portfolio-item-img'>

                            </div>
                            <div className='portfolio-item-bio col-md-7 battleship p-md-5'>
                                <div className='tags-list'>
                                </div>
                                <h1 className='portfolio-item-title mb-4'>Battleship</h1>
                                <p className='portfolio-item-desc mb-4 fs-5'>
                                    A classic game of Battleship. Play against the computer and see if you can beat it.
                                </p>
                                <ButtonGroup className='mb-5 mb-sm-2'>
                                <a href='https://github.com/Dinssa/battleship' target='_blank'>
                                    <Button variant='dark' className='fw-bold p-2 px-3 p-md-3 px-md-5'>
                                    ReadMe
                                    </Button>
                                </a>
                                <a href='https://dinssa.github.io/battleship/' target='_blank' className='ps-2'>
                                    <Button variant='dark' className='fw-bold p-2 px-3 p-md-3 px-md-5'>
                                    Demo
                                    </Button>
                                </a>
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='skills' className='skills'>
                <div className='inner'>
                    <div className='skills-text container mt-4'>
                        <h2 className='fs-2'>Behold, this wall of logos of all the tech I claim to master</h2>
                        <h4 className='fs-5'>Don’t believe me? Why don’t you test me with a job offer and see for yourself? 😉</h4>
                        <div id="skills-list">
                            <div className='skills-item'>
                                <i class="fa-brands fa-html5"></i>
                                <h5>HTML</h5>
                            </div>
                            <div className='skills-item'>
                                <i class="fa-brands fa-css3-alt"></i>
                                <h5>CSS</h5>
                            </div>
                            <div className='skills-item'>
                                <i class="fa-brands fa-js"></i>
                                <h5>JavaScript</h5>
                            </div>
                            <div className='skills-item'>
                                <i class="fa-brands fa-react"></i>
                                <h5>React</h5>
                            </div>
                            <div className='skills-item'>
                                <i class="fa-brands fa-angular"></i>
                                <h5>Angular</h5>
                            </div>
                            <div className='skills-item'>
                                <i class="fa-brands fa-sass"></i>
                                <h5>SASS</h5>
                            </div>
                            <div className='skills-item'>
                                <i class="fa-brands fa-python"></i>
                                <h5>Python</h5>
                            </div>
                            <div className='skills-item'>
                                <i class="fa-brands fa-java"></i>
                                <h5>Java</h5>
                            </div>
                            <div className='skills-item'>
                            <img class='skill-logo' src={process.env.PUBLIC_URL +'/assets/logos/haskell.svg'} ></img>
                                <h5>Haskell</h5>
                            </div>
                            <div className='skills-item'>
                            <img class='skill-logo' src={process.env.PUBLIC_URL +'/assets/logos/cplusplus.svg'} ></img>
                                <h5>C++</h5>
                            </div>
                            <div className='skills-item'>
                                <i class="fa-brands fa-node-js"></i>
                                <h5>Node.js</h5>
                            </div>
                            <div className='skills-item'>
                            <img class='skill-logo' src={process.env.PUBLIC_URL +'/assets/logos/expressjs.svg'} ></img>
                                <h5>Express</h5>
                            </div>
                            <div className='skills-item'>
                            <img class='skill-logo' src={process.env.PUBLIC_URL +'/assets/logos/djangoproject.svg'} ></img>
                                <h5>Django</h5>
                            </div>
                            <div className='skills-item'>
                                <i class="fa-brands fa-php"></i>
                                <h5>PHP</h5>
                            </div>
                            <div className='skills-item'>
                                <i class="fa-brands fa-linux"></i>
                                <h5>Linux</h5>
                            </div>
                            <div className='skills-item'>
                                <img class='skill-logo' src={process.env.PUBLIC_URL +'/assets/logos/mongodb.svg'} ></img>
                                <h5>MongoDB</h5>
                            </div>
                            <div className='skills-item'>
                                <img class='skill-logo' src={process.env.PUBLIC_URL +'/assets/logos/pocoo_flask.svg'} ></img>
                                <h5>Flask</h5>
                            </div>
                            <div className='skills-item'>
                                <i class="fa-brands fa-figma"></i>
                                <h5>Figma</h5>
                            </div>
                            <div className='skills-item'>
                                <img class='skill-logo' src={process.env.PUBLIC_URL +'/assets/logos/adobe_illustrator.svg'} ></img>
                                <h5>Illustrator</h5>
                            </div>
                            <div className='skills-item'>
                                <i class="fa-brands fa-github"></i>
                                <h5>GitHub</h5>
                            </div>
                            <div className='skills-item'>
                                <i class="fa-brands fa-gitlab"></i>
                                <h5>GitLab</h5>
                            </div>
                            <div className='skills-item'>
                                <i class="fa-brands fa-digital-ocean"></i>
                                <h5>Digital Ocean</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='contact' className='contact bg-purple'>
                <div className='inner'>
                    <div className='contact-text text-white container'>
                        <div className='row mt-5'>
                            <div className='col-md-6'>
                                <h1 className='about-start fw-bold pb-5 pe-4'>Say 👋 Hi!</h1>
                                <h2 className='me-lg-5 pe-md-4 letter-spacing-1 pb-5 pb-md-1 fs-3'>
                                    I enjoy meeting new people and discussing new projects and opportunities. If you'd like to find our more about me, or just want to say hi, please feel free to drop me a message.
                                </h2>
                            </div>
                            <div className='col-md-6'>
                                <div id="contact-form">
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group controlId='formName' className='mb-4'>
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                            type='text'
                                            placeholder=''
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            />
                                        </Form.Group>

                                        <Form.Group controlId='formEmail' className='mb-4'>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                            type='email'
                                            placeholder=''
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </Form.Group>

                                        <Form.Group controlId='formMessage' className='mb-4'>
                                            <Form.Label>Message</Form.Label>
                                            <Form.Control
                                            as='textarea'
                                            rows={3}
                                            placeholder=''
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            />
                                        </Form.Group>

                                        <Button variant='moonstone' type='submit'>
                                            Submit
                                        </Button>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer className='footer bg-purple'>
                <div className='inner'>
                    <div className='footer-text text-white container'>
                        <div className='row'>
                            <div className='col-md-8 text-center text-md-start'>
                              © 2023 No rights reserved. Shared with you.
                            </div>
                            <div className='col-md-4'>
                                <div className='footer-links'>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </footer>                    
            {showButton && (
                <button className='back-to-top' onClick={handleClick}>
                    <i class="bi bi-chevron-double-up"></i>
                </button>
            )}
        </div>)
}