import './TopNav.css'
import { Navbar, Nav, Dropdown, Container, Offcanvas } from 'react-bootstrap';
import { Link, NavLink } from "react-router-dom"
import { useMediaQuery } from 'react-responsive'
import { useState } from 'react';

export default function TopNav() {

    const expand = 'md';
    const isMd = useMediaQuery({ query: '(min-width: 768px)' });

    const [menuExpanded, setMenuExpanded] = useState(false);
    const toggleMenu = () => setMenuExpanded(!menuExpanded);

    const handleClose = () => setMenuExpanded(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const scrollToAbout = (event) => {
        event.preventDefault();
        menuExpanded && toggleMenu();
        window.scrollTo({
            top: calcOffset('about'),
            behavior: 'smooth'
        });
    };

    const scrollToSkills = (event) => {
        event.preventDefault();
        menuExpanded && toggleMenu();
        window.scrollTo({
            top: calcOffset('skills'),
            behavior: 'smooth'
        });
    };

    const scrollToPortfolio = (event) => {
        event.preventDefault();
        menuExpanded && toggleMenu();
        window.scrollTo({
            top: calcOffset('portfolio'),
            behavior: 'smooth'
        });
    };

    const scrollToContact = (event) => {
        event.preventDefault();
        menuExpanded && toggleMenu();
        window.scrollTo({
            top: calcOffset('contact'),
            behavior: 'smooth'
        });
    };

    function calcOffset(elementId) {
        const element = document.getElementById(elementId);
        const navbarHeight = document.querySelector('nav').offsetHeight;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - navbarHeight;
        return offsetPosition;
    }


  return (
    <Navbar expand={expand} className="bg-purple navbar p-0" variant='dark' collapseOnSelect>
        <Container fluid>
            <Navbar.Brand href="" className=''>
                <Link to="/" className='page-title' onClick={scrollToTop}>
                    <h3 className="p-1 mb-0 p-3">manny dinssa</h3>
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle 
                aria-controls={`offcanvasNavbar-expand-${expand}`} 
                onClick={toggleMenu}
            />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              className="bg-purple"
              style={{ maxWidth: !isMd ? '300px' : 'none' }}
              restoreFocus={false}
              show={menuExpanded}
              onHide={handleClose}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} className='text-white ms-2'>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1">
                <>
                    {isMd ? 
                        <div className='d-flex flex-row ms-3 justify-content-center align-items-center'>
                            <Nav.Link>
                                <li className="NavItem">
                                    <a href="#about" onClick={scrollToAbout}>About</a>
                                </li>
                            </Nav.Link>
                            <Nav.Link>
                                <li className="NavItem">
                                    <a href="#portfolio" onClick={scrollToPortfolio}>Portfolio</a>
                                </li>
                            </Nav.Link>
                            <Nav.Link>
                                <li className="NavItem">
                                    <a href="#skills" onClick={scrollToSkills}>Skills</a>
                                    {/* <NavLink to="/skills" activeClassName="active">Skills</NavLink> */}
                                </li>
                            </Nav.Link>
                            <Nav.Link className='ms-3 me-1'>
                                <li className="NavItem contact text-white">
                                    <a href="#contact" onClick={scrollToContact}>Get in touch</a>
                                </li>
                            </Nav.Link> 
                        </div>
                        : 
                        <div className='d-flex flex-column mt-4 mt-md-2 justify-content-center align-items-center pt-4'>
                            <Nav.Link>
                                <li className="NavItem">
                                    <a href="#about" onClick={scrollToAbout}>About</a>
                                </li>
                            </Nav.Link>
                            <Nav.Link>
                                <li className="NavItem">
                                    <a href="#portfolio" onClick={scrollToPortfolio}>Portfolio</a>
                                </li>
                            </Nav.Link>
                            <Nav.Link>
                                <li className="NavItem">
                                    <a href="#skills" onClick={scrollToSkills}>Skills</a>
                                    {/* <NavLink to="/skills" activeClassName="active">Skills</NavLink> */}
                                </li>
                            </Nav.Link>
                            <Nav.Link>
                                <li className="NavItem contact text-white mt-3">
                                    <a href="#contact" onClick={scrollToContact}>Get in touch</a>
                                </li>
                            </Nav.Link>                           
                        </div>
                    }
                </>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
  );
}