// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar{
    padding: 0.5rem;
    height: 5rem;
    width: 100%;
    /* background-color: #170A13; */
    z-index: 100;
    position: fixed;
    top: 0;
}

.page-title{
    text-decoration: none;
    color: #ffffff;
}

.NavItem > a {
    text-decoration: none;
    color: #ffffff;
    font-size: 0.9rem;
    font-weight: 600;
    letter-spacing: 0.02rem;
    margin: 0.5rem;
    padding-bottom: 0.33rem;
    transition: all 0.2s ease-in-out;
    border-bottom: 1px solid rgba(168, 147, 152, 0);
    transition: border-bottom 400ms;
}

.NavItem > a:hover{
    color: #a89398;
    font-weight: 600;
    /* background-color: var(--grey1); */
    border-bottom: 1px solid rgba(168, 147, 152, 1);
}

button.navbar-toggler.collapsed{
    background-color: var(--pink);
    border: none;
}

.NavItem{
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-link{
    padding: 0;
}

.NavItem.contact{
    background-color: #58A4B0;
    color: var(--purple);
    transition: all 0.2s ease-in-out;
}

.NavItem.contact > a{
    padding-bottom: 0;
    padding: 0 0.7rem 0 0.7rem;
    border-bottom: none;
    color: inherit;
    font-weight: 800;
}

.NavItem.contact:hover {
    opacity: 0.7;
}`, "",{"version":3,"sources":["webpack://./src/components/global/TopNav.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,+BAA+B;IAC/B,YAAY;IACZ,eAAe;IACf,MAAM;AACV;;AAEA;IACI,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI,qBAAqB;IACrB,cAAc;IACd,iBAAiB;IACjB,gBAAgB;IAChB,uBAAuB;IACvB,cAAc;IACd,uBAAuB;IACvB,gCAAgC;IAChC,+CAA+C;IAC/C,+BAA+B;AACnC;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,oCAAoC;IACpC,+CAA+C;AACnD;;AAEA;IACI,6BAA6B;IAC7B,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,yBAAyB;IACzB,oBAAoB;IACpB,gCAAgC;AACpC;;AAEA;IACI,iBAAiB;IACjB,0BAA0B;IAC1B,mBAAmB;IACnB,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".navbar{\n    padding: 0.5rem;\n    height: 5rem;\n    width: 100%;\n    /* background-color: #170A13; */\n    z-index: 100;\n    position: fixed;\n    top: 0;\n}\n\n.page-title{\n    text-decoration: none;\n    color: #ffffff;\n}\n\n.NavItem > a {\n    text-decoration: none;\n    color: #ffffff;\n    font-size: 0.9rem;\n    font-weight: 600;\n    letter-spacing: 0.02rem;\n    margin: 0.5rem;\n    padding-bottom: 0.33rem;\n    transition: all 0.2s ease-in-out;\n    border-bottom: 1px solid rgba(168, 147, 152, 0);\n    transition: border-bottom 400ms;\n}\n\n.NavItem > a:hover{\n    color: #a89398;\n    font-weight: 600;\n    /* background-color: var(--grey1); */\n    border-bottom: 1px solid rgba(168, 147, 152, 1);\n}\n\nbutton.navbar-toggler.collapsed{\n    background-color: var(--pink);\n    border: none;\n}\n\n.NavItem{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.nav-link{\n    padding: 0;\n}\n\n.NavItem.contact{\n    background-color: #58A4B0;\n    color: var(--purple);\n    transition: all 0.2s ease-in-out;\n}\n\n.NavItem.contact > a{\n    padding-bottom: 0;\n    padding: 0 0.7rem 0 0.7rem;\n    border-bottom: none;\n    color: inherit;\n    font-weight: 800;\n}\n\n.NavItem.contact:hover {\n    opacity: 0.7;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
