// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary: #364958;
  --secondary: #3B6064;
  --third: #55828B;
  --fourth: #87BBA2;
  --fifth: #C9E4CA;
  --success: #1d592b;
  --info: #89C1E0;
  --warning: #D69432;
  --danger: #9b2929;
  --light: #F8F9FA;
  --grey1: #E9ECEF;
  --grey2: #DEE2E6;
  --grey3: #CED4DA;
  --grey4: #ADB5BD;
  --grey5: #6C757D;
  --grey6: #495057;
  --grey7: #343A40;
  --dark: #212529;
  --purple: #170A13;
  --pink: #a68990;
  --moonstone: #58A4B0;
  --majorelle: #5E4AE3;
  --beige: #E4E6C3;
}

html{
  /* background-color: var(--light); */
  background-color: var(--purple);
  overflow: auto;
}

#root{
  background-color: var(--purple);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.letter-spacing-1{
  letter-spacing: 1px;
}

.letter-spacing-2{
  letter-spacing: 2px;
}

.text-justify {
  text-align: justify;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,oBAAoB;EACpB,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,oCAAoC;EACpC,+BAA+B;EAC/B,cAAc;AAChB;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[":root {\n  --primary: #364958;\n  --secondary: #3B6064;\n  --third: #55828B;\n  --fourth: #87BBA2;\n  --fifth: #C9E4CA;\n  --success: #1d592b;\n  --info: #89C1E0;\n  --warning: #D69432;\n  --danger: #9b2929;\n  --light: #F8F9FA;\n  --grey1: #E9ECEF;\n  --grey2: #DEE2E6;\n  --grey3: #CED4DA;\n  --grey4: #ADB5BD;\n  --grey5: #6C757D;\n  --grey6: #495057;\n  --grey7: #343A40;\n  --dark: #212529;\n  --purple: #170A13;\n  --pink: #a68990;\n  --moonstone: #58A4B0;\n  --majorelle: #5E4AE3;\n  --beige: #E4E6C3;\n}\n\nhtml{\n  /* background-color: var(--light); */\n  background-color: var(--purple);\n  overflow: auto;\n}\n\n#root{\n  background-color: var(--purple);\n}\n\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.letter-spacing-1{\n  letter-spacing: 1px;\n}\n\n.letter-spacing-2{\n  letter-spacing: 2px;\n}\n\n.text-justify {\n  text-align: justify;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
